
import Vue from 'vue'

export default Vue.extend({
  name: 'ClassSchedule',

  data: () => ({ }),
  computed: {
    groups () {
      return this.$store.state.schedule
    },

    days () {
      return this.$store.getters.days
    }
  },
  methods: {
    getTimes (c: {
        start: string|string[];
        end: string|string[];
    }) {
      if (c.start instanceof Array) {
        return (c.start as Array<string>)
          .map((start, idx) => {
            return { start, end: c.end[idx] };
          });
      }

      return [c]
    }
  }
})
