<template>
  <div class="schedule">
    <h1>
      Schedule
      <sup style="font-size:.5em;">(<em>June 2024 - May 2025</em>)</sup>
    </h1>
    <schedule></schedule>
    <h1>Tuition</h1>
    <v-row>
      <v-col class="col-12 col-sm-6">
        <p>
          An annual registration of $25 is required and covers all classes including those added after initial registration.
          This fee is <em>not refundable</em>.
        </p>
        <p>
           Tuition is per month per dance style. It is not prorated or increased for absence, short (3 class) or long (5 class) months.
        </p>
        <p>
          <!-- <router-link to="recitals"> -->
          Spring Recital
          <!-- </router-link>  -->
          is not included in tuition. There is a Recital Fee per dancer and a costume fee per dancer per class
          all of which is non-refundable. Recital details will be provided in December.
        </p>
      </v-col>
      <v-col class="cols col-12 col-sm-6">
        <v-simple-table>
          <thead>
            <tr>
              <th><br/>Class</th>
              <th><br/>Tuition</th>
              <th><sup>June 3rd - July 27th</sup><br/>Summer </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="{name, tuition, tip, single} of classes" :key="name">
              <v-tooltip left v-if="tip">
                <template v-slot:activator="{on, attrs}">
                  <td v-bind="attrs" v-on="on" v-html="name"></td>
                </template>
                <span class="d-block" style="max-width: 150px;">{{tip}}</span>
              </v-tooltip>
              <td v-else v-html="name"></td>
              <td>{{ tuition || 'N/A' }}</td>
              <td>{{single}}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-col><p>* Private lessons are available</p></v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import Schedule from '@/components/ClassSchedule.vue'

const MONTHLY_DISCLAIMER = `Once a month events are not offered in
      June or July. Monthly classes are scheduled for the first
      Saturday every month from August to May and are priced per class (includes
      drinks and snacks)`;

const SOCIAL_DISCLAIMER = `Homeschool social: (Stretch, Areobic dance, Lunch and Safe interaction).
      These weekly social events require registration in advance (by the night before). Lunch
      is not included. Please bring your own lunch. Cold drinks will be provided.`;

export default {
  name: 'Home',
  components: {
    Schedule
  },
  data: () => ({
    classes: [{
      name: '30 Minutes',
      tuition: '$55/month',
      single: '$14/class'
    }, {
      name: '45 Minutes',
      tuition: '$70/month',
      single: '$17/class'
    }, {
      name: '60 Minutes',
      tuition: '$80/month',
      single: '$20/class'
    }],

    monthlyDisclaimer: MONTHLY_DISCLAIMER,
    socialDisclaimer: SOCIAL_DISCLAIMER
  })
}
</script>
